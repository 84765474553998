// Import modules
import Turbolinks from 'turbolinks';
import Rails from "@rails/ujs";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import Chartkick from 'chartkick';
import Chart from 'chart.js/auto';
import "trix"
import "@rails/actiontext"

// Require statements
require("turbolinks").start()
require("@rails/activestorage").start()
require("../channels")
require("chartkick")
require("chart.js")
require("./pagy-module.js")
require("trix")
require("@rails/actiontext")

// Import local scripts
import './plugins.bundle.js';
import './scripts.bundle.js';

// Start services
Rails.start()
Turbolinks.start()

// Assign global variables
window.Chartkick = Chartkick;